import { useEffect , useState, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    studentActions,
    audioActions,
    homeworkActions,
    readingActions,
  } from "_store";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import axios from "axios";
import { Modal, Button } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import $ from 'jquery';
const settings = {
    bars: 7,
    spacing: 3,
    width: 5,
    height: 100
  };
  const WordDisplay = ({ words }) => {
    const [visibleWords, setVisibleWords] = useState([]);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
  
    useEffect(() => {
      let currentIndex = 0;
  
      // Update visible words every 3 seconds
      const intervalId = setInterval(() => {
        setVisibleWords(() => {
          const nextVisibleWords = words.slice(currentIndex, currentIndex + 3);
          setHighlightedIndex(0); // Highlight the first word initially
  
          // Reset the highlight index for the next rotation
          setTimeout(() => {
            setHighlightedIndex(-1);
          }, 100);
  
          currentIndex = (currentIndex + 1) % words.length;
          return nextVisibleWords;
        });
      }, 3000);
  
      // Cleanup interval on component unmount
      return () => clearInterval(intervalId);
    }, [words]);
  
    useEffect(() => {
      // Initialize the visible words when the component mounts
      setVisibleWords(words.slice(0, 3));
    }, [words]);
  
    return (
      <div>
        <ul>
          {visibleWords.map((word, index) => (
            <li
              key={index}
              style={{
                backgroundColor: index === highlightedIndex ? 'lightblue' : 'transparent',
                transition: "transform 0.5s ease-in-out",
              }}
            >
              {word}
            </li>
          ))}
        </ul>
      </div>
    );
  };
  
  export function TextBookVocabReadOutLoud() {
    // Replace this with your actual list of words
    const wordList = ['declare', 'demand', 'jabber', 'recess', 'smash', 'stink bug', 'survey', 'toughest', 'weird', 'whisper'];
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((x) => x.auth);
    const { homework_id, program_id } = useParams();
    const { homework } = useSelector((x) => x.homework);
    const { reading } = useSelector((x) => x.reading);
    const { student } = useSelector((x) => x.student);
    const { myrecordings } = useSelector((x) => x.myrecordings);
    const [textValue, setTextValue] = useState("");
    const initialMinutes = 2;
    const initialSeconds = initialMinutes * 60;
    const [teacherResult, setTeacherResult] = useState(null);
    const [totalSeconds, setTotalSeconds] = useState(initialSeconds);
    const audioRef = useRef(null);
    const [audioFile, setAudioFile] = useState("");
    const [filePath, setFilePath] = useState("");
    const [audioQuality, setAudioQuality] = useState("");
    const [recordId, setRecordId] = useState("");
    const recorderControls = useAudioRecorder();
    const [showDialog, setShowDialog] = useState(false);
    const refs = useRef([]);
    const volumemeter = useRef(0);
    const volumeRefs = useRef(new Array(settings.bars));
    const [imageSource, setImageSource] = useState('/images/record.png');
    useEffect(() => {
      if (recordId) {
        var file = filePath;
        var text = (homework && homework.data) ? homework.data.homework_text :'';
        var type = 'Vocab Read Out Loud';
        dispatch(readingActions.readingaudiorecording({ recordId,text, file, type }));

      }
    }, [recordId]);
    useEffect(() => {
      if (reading) {
          window.location.href = '/ReadOutLoudGrader/'+recordId;
      }
    }, [reading]);
    console.log(reading,'get user');
    const blobToBase64 = (blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    };
  
    const addAudioElement = async (blob) => {
      const url = URL.createObjectURL(blob);
      //alert("lalit");
  
      blobToBase64(blob).then((res) => {
        // do what you wanna do
        console.log(res, "blob"); // res is base64 now
      });
      alert('yes');
      const formData = new FormData();
      formData.append("audio", blob);
      formData.append("user", authUser.user.student_id);
      formData.append("homework_id", homework_id);
      var text = (homework && homework.data) ? homework.data.homework_text :'';
      formData.append("text", text);
      formData.append("type", 'Vocab Read Out Loud');
      formData.append("sub_type", 'Vocab Read Out Loud');
      formData.append("program_id", program_id);
      
      const response = await axios.post(
        "https://api.webtracktechnologies.com.au:8002/audio-recording",
        formData
      );
      setAudioFile(response.data.data);
      setFilePath(response.data.filepath);
      setRecordId(response.data.audio_record.id);
      //  const audio1 = document.createElement("audio");
      //   audio1.src = url;
      //   audio1.controls = true;
    };
  
    const handleTextareaChange = (event) => {
      setTextValue(event.target.value);
    };
  
    useEffect(() => {
      if (recorderControls.isRecording) {
        const interval = setInterval(() => {
          if (totalSeconds > 0) {
            setTotalSeconds(totalSeconds - 1);
          }
        }, 1000);
  
        return () => clearInterval(interval);
      }
    }, [recorderControls.isRecording, totalSeconds]);
  
    const formatTime = (timeInSeconds) => {
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = timeInSeconds % 60;
      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };
  

    useEffect(() => {
      dispatch(homeworkActions.getHomeWorkByRecordId({ homework_id }));
      $('.audio-recorder-mic').attr('src',imageSource);
    }, []);
  
  
    const startRecording = () => {
      
        recorderControls.startRecording;
      
    }

    const getMedia = () => {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(stream => {
          const audioContext = new AudioContext();
          const analyser = audioContext.createAnalyser();
          const microphone = audioContext.createMediaStreamSource(stream);
          const javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);
          analyser.smoothingTimeConstant = 0.4;
          analyser.fftSize = 1024;
          microphone.connect(analyser);
          analyser.connect(javascriptNode);
          javascriptNode.connect(audioContext.destination);
          javascriptNode.onaudioprocess = () => {
            var array = new Uint8Array(analyser.frequencyBinCount);
            analyser.getByteFrequencyData(array);
            var values = 0;
            var length = array.length;
            for (var i = 0; i < length; i++) {
              values += array[i];
            }
            volumemeter.current = values / length;
          };
        })
        .catch(function(err) {
          /* handle the error */
        });
    };
  
    useEffect(getMedia, []);
    useEffect(() => {
      const intervalId = setInterval(() => {
        volumeRefs.current.unshift(volumemeter.current);
        volumeRefs.current.pop();
        if(refs.current.length > 0){
          //console.log(refs.current,'get reference');return false;
          for (let i = 0; i < refs.current.length; i++) {
  if(refs.current[i] != '' && refs.current[i] != null){
  
    refs.current[i].style.transform = `scaleY(${volumeRefs.current[i] /
      100})`;
  }
          }
        }
      }, 20);
      return () => {
        clearInterval(intervalId);
      };
    }, []);
  
    const createElements = () => {
      let elements = [];
  
      for (let i = 0; i < settings.bars; i++) {
        elements.push(
          <div
            ref={ref => refs.current.push(ref)}
            key={`vu-${i}`}
            style={{
              position: "absolute",
              background: "#5ac63d",
              borderRadius: settings.width + "px",
              width: settings.width + "px",
              height: Math.sin((i / settings.bars) * 4) * settings.height + "px",
              left: i * (settings.width + settings.spacing) + "px",
              top:
              settings.height / 2 -
              Math.sin((i / settings.bars) * 4) * settings.height / 2 +
              "px"
            }}
          />
        );
      }
      return elements;
    };
    useEffect(() => {
      if(recorderControls.stopRecording){
        $('.audio-recorder-mic').attr('src',imageSource);
      }
  }, [recorderControls.stopRecording]); 
    return (
      <section>
        <div className="test">
          <h1>TextBook Vocab Read Out Loud</h1>
          <WordDisplay words={wordList} />
          <div className="mt-4">
              <div className="block my-recording-micro">
                <i className="fa fa-microphone big" aria-hidden="true" />
                {
          (recorderControls.isRecording ) ? 
          <div className="micro-sound-meter" style={{ position: "relative" }}>{createElements()}</div>: ''
        }
                <p>Countdown Timer</p>
  
               
                {recorderControls.isRecording ? (
  
                  (!showDialog) ?
                  <>
                  <p>
                  {recorderControls.isRecording
                    ? formatTime(totalSeconds)
                    : "02:00"}
                </p>
                  <button
                    onClick={recorderControls.stopRecording}
                    className="test-btn stop"
                  >
                    Stop
                  </button>
                  <button >Listen</button>
                  </>
                  :
                  ''
                ) : (
                  (!showDialog) ? 
                  <>
                  <button
                    className="test-btn"
                    onClick={() => startRecording()}
                  >
                    
                    <AudioRecorder
                      onRecordingComplete={(blob) => addAudioElement(blob)}
                      recorderControls={recorderControls}
                      audioTrackConstraints={{
                        noiseSuppression: true,
                        echoCancellation: true,
                      }}
                      downloadOnSavePress={true}
                      downloadFileExtension="mp3"
                      showVisualizer={true}
                    />
                  </button>
                   <button >Listen</button>
                   </>
                  : ''
                )}
  
                {audioFile ? (
                  <>
                    <audio className="hide" ref={audioRef} controls>
                      <source src={audioFile} type="audio/mp3" />
                      Your browser does not support the audio element.
                    </audio>
                   
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
        </div>
      </section>
    );
  };
  
  export default TextBookVocabReadOutLoud;
  

