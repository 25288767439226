import { useEffect , useState, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    libraryActions
  } from "_store";
  import { useParams } from "react-router-dom";
  import { Table } from "react-bootstrap";
  import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
  import axios from "axios";
  import ReactHtmlParser from 'react-html-parser';

export const FillInTheBlank = () => {
    const dispatch = useDispatch();
    const { homework_id, program_id } = useParams();
    const [filledBlanks, setFilledBlanks] = useState(Array(6).fill(''));
    const [selectedWord, setSelectedWord] = useState('');
    const [selectedWordsArray, setSelectedWordsArray] = useState([]);
    const { library } = useSelector((x) => x.library);
    const [availableAnswerOptions, setAvailableAnswerOptions] = useState([]);
    const [score, setScore] = useState(0);
    const [chartData, setChartData] = useState([]);
    const [percentage, setPercentage] = useState(0);
    const [results, setResults] = useState([]);
    const { user: authUser } = useSelector((x) => x.auth);
    const [correctAnswer, setCorrectAnswer] = useState();
    const [yourAnswer, setYourAnswer] = useState();
    const [answerOptions, setAnswerOptions] = useState([]);
    const [audio, setAudio] = useState();
    const [blankId, setBlankId] = useState();

    // const data = {
    //   "message": "Vocabulary blanks program fetched successfully",
    //   "data": {
    //     "blanks": "_ was supposed to ask _ a question and write _ the answers. Then he would have to do a report on it. The results would be buried in a time capsule. It would be dug up in fifty _ That was why _ wanted to think of a real good question. _ was your favorite _ asked Stuart.",
    //     "audio": "https://api.webtracktechnologies.com.au:8002/uploads/books/3/1702363816773_lew7z2Marvinwassupposedtoaskeve.mp3",
    //     "answer": ['Marvin', 'Marvin', 'would', 'question', 'question.', 'have']
    //   }
    // };
 
    const words = (library && library.data) ? library.data.blanks.split(' ') : [];
  console.log(library,'get lib');
    const handleWordClick = (word) => {
      setSelectedWord(word);
    };
  
    const handleBlankClick = (index) => {
      if (selectedWord !== '') {
        setFilledBlanks((prevFilledBlanks) => {
          const newFilledBlanks = [...prevFilledBlanks];
          newFilledBlanks[index] = selectedWord;
          return newFilledBlanks;
        });
  
        setSelectedWordsArray((prevArray) => [
          ...prevArray,
          {
            id: prevArray.length + 1,
            text: selectedWord,
          },
        ]);
  
        setSelectedWord('');
    // Find the index of the selected word in available answer options
    const wordIndex = availableAnswerOptions.indexOf(index);

    // Remove only the selected word from available answer options
    if (wordIndex !== -1) {
      setAvailableAnswerOptions((prevOptions) => [
        ...prevOptions.slice(0, wordIndex),
        ...prevOptions.slice(wordIndex + 1),
      ]);
    }
        // // Remove the selected word from answer options
        // setAvailableAnswerOptions((prevOptions) =>
        //   prevOptions.filter((option) => option !== selectedWord)
        // );
      }
    };
  
    const handleAnswerOptionClick = (option) => {
      handleWordClick(option);
  // Find the index of the selected word in available answer options
  const wordIndex = availableAnswerOptions.indexOf(option);

  // Remove only the selected word from available answer options
  if (wordIndex !== -1) {
    setAvailableAnswerOptions((prevOptions) => [
      ...prevOptions.slice(0, wordIndex),
      ...prevOptions.slice(wordIndex + 1),
    ]);
  }
    };
  
    // const handleFilledBlankClick = (index) => {
    //   // Move the filled blank word back to the answer options
    //   const filledWordToMoveBack = filledBlanks[index];
    //   if (filledWordToMoveBack) {
    //     // Clear the filled blank
    //     setFilledBlanks((prevFilledBlanks) => {
    //       const newFilledBlanks = [...prevFilledBlanks];
    //       newFilledBlanks[index] = '';
    //       return newFilledBlanks;
    //     });
  
    //     // Add the word back to the available answer options
    //     setAvailableAnswerOptions((prevOptions) => [
    //       ...prevOptions,
    //       filledWordToMoveBack,
    //     ]);
  
    //     // Remove the word from the selected words array
    //     setSelectedWordsArray((prevArray) =>
    //       prevArray.filter((item) => item.text !== filledWordToMoveBack)
    //     );
    //   }
    // };

    const handleFilledBlankClick = (index) => {
alert('yes');
      // Move the filled blank word back to the answer options
      const filledWordToMoveBack = filledBlanks[index];
      if (filledWordToMoveBack) {
        // Clear the filled blank
        setFilledBlanks((prevFilledBlanks) => {
          const newFilledBlanks = [...prevFilledBlanks];
          newFilledBlanks[index] = '';
          return newFilledBlanks;
        });
    
        // Add the word back to the available answer options
        setAvailableAnswerOptions((prevOptions) => [
          ...prevOptions,
          filledWordToMoveBack,
        ]);
    
        // Remove the word from the selected words array
        setSelectedWordsArray((prevArray) =>
          prevArray.filter((item) => item.text !== filledWordToMoveBack)
        );
      }
    };
  
    const handleSaveClick = async() => {
      // You can send the selectedWordsArray to your API endpoint here
      // For demonstration, let's log it to the console
      console.log('Selected Words Array:', selectedWordsArray);
      const result = {
        id:blankId,
         answer: selectedWordsArray
       }
      const response = await axios.post(
          "https://api.webtracktechnologies.com.au:8002/library/quiz/checkresult",
          result,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
              // Add other headers if needed
            },
          }
        );
        console.log(response,'get response');
        if(response){
            // Count the correct answers and set as the score
          const score = response.data.answers.filter((answer) => answer.isCorrect).length;
          setScore(score);
    // Update the results based on the response
    const newResults = response.data.answers.map((answer) => {
      return {
        id: answer.id,
        text: answer.isCorrect ? (
          <span style={{ backgroundColor: 'green' }}>{answer.text}</span>
        ) : (
          <span style={{ backgroundColor: 'red' }}>{answer.text}</span>
        ),
      };
    });

    setResults(newResults);
    setCorrectAnswer(response.data.correctparagraph);
    setYourAnswer(response.data.userparagraph);

    // Update the filled blanks in the paragraph
const updatedParagraph = words.map((word, index) => {
  const correspondingAnswer = results.find(answer => answer.id === index + 1);

  return (
    <span key={index}>
      {word.includes('_') ? (
        <span
          className={`blank ${correspondingAnswer ? correspondingAnswer.text.props.style : ''}`}
          onClick={() => handleBlankClick(index)}
        >
          {filledBlanks[index] || '______'}
        </span>
      ) : (
        <span onClick={() => handleWordClick(word)}>
          {filledBlanks[index] || word}
        </span>
      )}
      {' '}
    </span>
  );
});
// Extract text content from the React elements and join into a string
const updatedParagraphString = updatedParagraph.map(element => element.props.children).join(' ');

console.log(response.data.userparagraph,'updated',response.data.correctparagraph);


    setParagraphWithAnswers(updatedParagraph);

    const scoreprecentage=((score / answerOptions.length) * 100);
    const remaining=100-scoreprecentage;
    const result = []
      {
      
          result.push({ 'useranswer': response.data.userparagraph, 'correctanswer': response.data.correctparagraph })
      
      }
     // console.log(updatedParagraph,'result anjali');return false;
      setPercentage(scoreprecentage);
      setChartData([{ name: 'Your score', score: scoreprecentage, total: remaining  }]);
    const option = {
     // category: location.state.vocabulary.id,
      total_score:scoreprecentage,
      Studentid: authUser.user.student_id,
      type: "Listening Fill in the Blanks",
      sub_type: "Listening Fill in the Blanks",
      homework_id:homework_id,
      result: JSON.stringify(result)
    }
    console.log(option, "opt")
    const response1 = await axios.post(
      "https://api.webtracktechnologies.com.au:8002/homework/save-vocab-result",
      option,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          // Add other headers if needed
        },
      }
    );
        }


    };
    const [paragraphWithAnswers, setParagraphWithAnswers] = useState([]);

    useEffect(() => {
        dispatch(libraryActions.getFillInTheBlankForLibrary({ program_id }));
      }, []);

  useEffect(() => {
if(library && library.data){
  
  const shuffledAnswers = shuffleArray(library.data.answer);
  setAnswerOptions(shuffledAnswers);
  setAvailableAnswerOptions(shuffledAnswers);
  setAudio(library.data.audio);
  setBlankId(library.data.id);
}
  }, [library]);

  const shuffleArray = (array) => {
    return array
      .map(value => ({ value, sort: Math.random() })) // Create an array of objects with random sort keys
      .sort((a, b) => a.sort - b.sort)                // Sort the array based on random sort keys
      .map(({ value }) => value);                     // Extract the original values in random order
  };
  const handleRetakeQuiz = () => {

    window.location.reload();
  };

  const renderStarsForBlanks = (rating) => {
    var value;
    if (parseInt(rating) <= 10) {
      value = 1;
    } else if (parseInt(rating) < 21 && parseInt(rating) >= 11) {
      value = 2;
    } else if (parseInt(rating) < 41 && parseInt(rating) >= 21) {
      value = 3;
    } else if (parseInt(rating) < 61 && parseInt(rating) >= 41) {
      value = 4;
    } else if (parseInt(rating) <= 80 && parseInt(rating) >= 61) {
      value = 4;
    }else if (parseInt(rating) <= 100 && parseInt(rating) >= 81) {
      value = 5;
    }
    console.log(rating,'get percentage',value);
    let stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <i
          key={i}
          className={i <= value ? 'fa fa-star active' : 'fa fa-star'}
          aria-hidden="true"
        />
      );
    }
    return stars;
  };
    return (
        <section>
      <div className='test fill-in-the-blanks'>
      <h2>Listening: Fill in the blanks</h2>
      {chartData.length == 0 ? <>
      {(paragraphWithAnswers.length === 0) ?   <p>
          {words.map((word, index) => (
            <span key={index}>
              {word.includes('_') ? (
                <span
                className={`blank ${filledBlanks[index]}`}
                  onClick={() => handleBlankClick(index)}
                >
                  {(filledBlanks[index] ?  filledBlanks[index]  : <span>______</span>) }
                </span>
              ) : (
                <span
                  onClick={() =>
                    filledBlanks[index] && handleFilledBlankClick(index)
                  }
                >
                  {filledBlanks[index] || word}
                </span>
              )}{' '}
            </span>
          ))}
        </p>: <p>{paragraphWithAnswers}</p>}
        {audio ?  <audio controls>
          <source src={audio} type="audio/mp3" />
          Your browser does not support the audio element.
        </audio> : ''}
       
        <div className='answer-option'>

          {availableAnswerOptions.map((word, index) => (
            <button key={index} onClick={() => handleAnswerOptionClick(word)}>
              {word}
            </button>
          ))}
                {results.map((result) => (
          <span key={result.id}>{result.text}{' '}</span>
        ))}
        </div>
            <div>
            <button className="btn btn-primary" onClick={handleSaveClick}>Save</button>
            </div>
            </>
:''}
           {(chartData.length >0) ?  <div>
        <BarChart width={500} height={300} data={chartData}>
      <XAxis dataKey="name" />
      <YAxis domain={[0, 100]} /> 
      <Tooltip />
      <Legend />
     <Bar dataKey="score" fill="#3a58e9b8" stackId="a" barSize={200}/>
  <Bar dataKey="total" fill="#e93a3a" stackId="a" barSize={200}/>
    </BarChart>
      <span className='score'>
    <div>
    <span className="rating">
              {/* <i className="fa fa-star active" aria-hidden="true" />
              <i className="fa fa-star" aria-hidden="true" />
              <i className="fa fa-star" aria-hidden="true" />
              <i className="fa fa-star" aria-hidden="true" />
              <i className="fa fa-star" aria-hidden="true" /> */}
              {renderStarsForBlanks(percentage)}
            </span>
</div>
</span>
<Table style={{ margin: '20px', width: '95%' }}>
            <thead>
              <th>Your Raw Score</th>
              <th>{score} / {answerOptions.length}</th>
            </thead>
            <thead>
              <th>Your Score</th>
              <th>{(percentage.toFixed(2))}%</th>
            </thead>
          </Table>
          <div className="quiz-results">
          
        <h2>Your Answer</h2>
        <p>{ReactHtmlParser(yourAnswer)}</p>
        <h2>Correct Answer</h2>
        <p>{ReactHtmlParser(correctAnswer)}</p>
        <button className='quiz-button' onClick={handleRetakeQuiz}>Retake</button>
          </div>
          </div>
           :''}
          {/* <ul style={{ listStyle: 'none', padding: 0 }}> */}
  {/* {userAnswers.map((answer, index) => (
    <li key={index}>
      {console.log(sentences[index])}
      {`Question ${index + 1}: Your Answer - ${answer}, Correct Answer - ${sentences[index].correct},Scrambled - ${sentences[index].scrambled}`}
    </li>
  ))}
</ul> */}
    </div>
        



      </section>
    );
      };
