import { useEffect,useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';

import { studentActions,homeworkActions ,libraryActions} from '_store';
import { useParams } from "react-router-dom";

export { HomeWorkVocabularyTypeByProgram };

function HomeWorkVocabularyTypeByProgram() {
  const dispatch = useDispatch();
  const { program_id } = useParams();
  const { homework } = useSelector((x) => x.homework);
  const { library } = useSelector((x) => x.library);
  const { student } = useSelector((x) => x.student);
  const [score, setScore] = useState();
  const [result, setResult] = useState();
  const [vocabResult, setVocabResult] = useState();
  const [listenVocabResult, setListenVocabResult] = useState();
  const [vocabReadResult, setVocabReadResult] = useState();
  const [sentenceReadResult, setSentenceReadResult] = useState();
  const [respondReadResult, setRespondReadResult] = useState();
  const [unscrambleResult, setUnscrambleResult] = useState();
  const [listenDictationResult, setListenDictationResult] = useState();
  const [listenFillInBlankResult, setListenFillInBlankResult] = useState();
  const [grammarMcqResult, setGrammarMCQResult] = useState();
  const [readingComprehensionResult, setReadingComprehensionResult] = useState();
  const [listeningComprehensionResult, setListeningComprehensionResult] = useState();

  useEffect(() => {
    if(student){
      var student_id = student.id;
      dispatch(homeworkActions.getProgramByProgramId({program_id, student_id}));
    }
    //dispatch(homeworkActions.getHomeWorkByRecordId({ homework_id }));
   
  }, [student]);

  useEffect(() => {
   
    if(homework && homework.data && homework.data.Audio_Records)
    {
      const audioRecords = homework.data?.Audio_Records;

      // Check if Audio_Records is empty
      const isAudioRecordsEmpty = !audioRecords || Object.keys(audioRecords).length === 0;
   
      if(!isAudioRecordsEmpty){
        if (
          (homework.data.Audio_Records['Read Out Loud'] && homework.data.Audio_Records['Read Out Loud'].result) ||
          (homework.data.Audio_Records['Library'] && homework.data.Audio_Records['Library'].result)
        ) {
          var jsonResult = homework.data.Audio_Records['Read Out Loud'] && homework.data.Audio_Records['Read Out Loud'].result
            ? JSON.parse(homework.data.Audio_Records['Read Out Loud'].result)
            : JSON.parse(homework.data.Audio_Records['Library'].result);
          setResult(jsonResult);
        }
        
        if(homework.data.Audio_Records['Textbook Vocab']){
          var jsonVocabResult = JSON.parse(homework.data.Audio_Records['Textbook Vocab'].result);
          setVocabResult(jsonVocabResult);
        }
        if(homework.data.Audio_Records['Vocabulary Writing: EngW2KorM']){
          var jsonVocabResult = JSON.parse(homework.data.Audio_Records['Vocabulary Writing: EngW2KorM'].result);
          setVocabWriteEW2KMResult(jsonVocabResult);
        }
        if(homework.data.Audio_Records['Vocabulary Writing: KorM2EngW']){
          var jsonVocabResult = JSON.parse(homework.data.Audio_Records['Vocabulary Writing: KorM2EngW'].result);
          setVocabWriteKM2EWResult(jsonVocabResult);
        }

        if(homework.data.Audio_Records['Vocabulary Writing: EngW2EngM']){
          var jsonVocabResult = JSON.parse(homework.data.Audio_Records['Vocabulary Writing: EngW2EngM'].result);
          setVocabWriteEW2EMResult(jsonVocabResult);
        }

        if(homework.data.Audio_Records['Vocabulary Writing: EngM2EngW']){
          var jsonVocabResult = JSON.parse(homework.data.Audio_Records['Vocabulary Writing: EngM2EngW'].result);
          setVocabWriteEM2EWResult(jsonVocabResult);
        }

        if(homework.data.Audio_Records['Vocabulary Listening Writing: Listen and write what you hear (English word to Korean meaning)']){
          var jsonVocabResult = JSON.parse(homework.data.Audio_Records['Vocabulary Listening Writing: Listen and write what you hear (English word to Korean meaning)'].result);
          setVocabWhatYouHearEW2KMResult(jsonVocabResult);
        }

        if(homework.data.Audio_Records['Vocabulary Listening Writing: Listen and write the correct meaning (English meaning to English word)']){
          var jsonVocabResult = JSON.parse(homework.data.Audio_Records['Vocabulary Listening Writing: Listen and write the correct meaning (English meaning to English word)'].result);
          setVocabCorrectMeaningEM2EWResult(jsonVocabResult);
        }

        
        if(homework.data.Audio_Records['Vocabulary Listening Writing: Listen and write what you hear (English word to English meaning)']){
          var jsonVocabResult = JSON.parse(homework.data.Audio_Records['Vocabulary Listening Writing: Listen and write what you hear (English word to English meaning)'].result);
          setVocabWhatYouHearEW2EMResult(jsonVocabResult);
        }

        if(homework.data.Audio_Records['Vocabulary Listening Writing: Listen and write the correct meaning (Korean meaning to English word)']){
          var jsonVocabResult = JSON.parse(homework.data.Audio_Records['Vocabulary Listening Writing: Listen and write the correct meaning (Korean meaning to English word)'].result);
          setVocabCorrectMeaningKM2EWResult(jsonVocabResult);
        }
        
        

        if(homework.data.Audio_Records['Listen Vocab']){
          var jsonListenVocabResult = JSON.parse(homework.data.Audio_Records['Listen Vocab'].result);
          setListenVocabResult(jsonListenVocabResult);
        }

        if(homework.data.Audio_Records['Vocab Read Out Loud']){
          var jsonVocabReadResult = JSON.parse(homework.data.Audio_Records['Vocab Read Out Loud'].result);
          setVocabReadResult(jsonVocabReadResult);
        }

        if(homework.data.Audio_Records['Sentence Read Out Loud']){
          var jsonSentenceReadResult = JSON.parse(homework.data.Audio_Records['Sentence Read Out Loud'].result);
          setSentenceReadResult(jsonSentenceReadResult);
        }

        if(homework.data.Audio_Records['Speaking Respond In English']){
          var jsonRespondInEngReadResult = JSON.parse(homework.data.Audio_Records['Speaking Respond In English'].result);
          setRespondReadResult(jsonRespondInEngReadResult);
        }
        
        if(homework.data.Audio_Records['Sentence Unscramble']){
          var jsonScrambleResult = JSON.parse(homework.data.Audio_Records['Sentence Unscramble'].result);
          setUnscrambleResult(jsonScrambleResult);
        }

        if(homework.data.Audio_Records['Listening Sentence Dicatation']){
          var jsonListenDictationResult = JSON.parse(homework.data.Audio_Records['Listening Sentence Dicatation'].result);
          setListenDictationResult(jsonListenDictationResult);
        }

        if(homework.data.Audio_Records['Listening Fill in the Blanks']){
          var jsonListenFillInBlankResult = JSON.parse(homework.data.Audio_Records['Listening Fill in the Blanks'].result);
          setListenFillInBlankResult(jsonListenFillInBlankResult);
        }

        if(homework.data.Audio_Records['Grammar MCQ']){
          var jsonGrammarMCQResult = JSON.parse(homework.data.Audio_Records['Grammar MCQ'].result);
          setGrammarMCQResult(jsonGrammarMCQResult);
        }

        if(homework.data.Audio_Records['Reading Comprehension']){
          var jsonReadingComprehensionResult = JSON.parse(homework.data.Audio_Records['Reading Comprehension'].result);
          setReadingComprehensionResult(jsonReadingComprehensionResult);
        }

        if(homework.data.Audio_Records['Listening Comprehension']){
          var jsonListeningComprehensionResult = JSON.parse(homework.data.Audio_Records['Listening Comprehension'].result);
          setListeningComprehensionResult(jsonListeningComprehensionResult);
        }
        
        
      }
       
    }else{

    }
  }, [homework]);

  const getFluencyAverageScore = (fluency) => {console.log(fluency,'fluency');
    let convertedWpm = fluency * 10;
    var value = 0;
    if (Math.round(convertedWpm) <= 60) {
      value = 0;
    } else if (Math.round(convertedWpm) >= 61 && Math.round(convertedWpm) <= 90) {
      value = 1;
    } else if (Math.round(convertedWpm) >= 91 && Math.round(convertedWpm) <= 120) {
      value = 2;
    } else if (Math.round(convertedWpm) >= 121 && Math.round(convertedWpm) <= 150) {
      value = 3;
    } else if (Math.round(convertedWpm) >= 151) {
      value = 4;
    } 
   // console.log(value,'get fluency',fluency);
    return value;
  }; 

  const calculateAccuracyPercentage = () => {
    if((result)){
      var sub = result.message.AAResult[0].analysis[0].readingSummary[0].nSub[0];
      var del = result.message.AAResult[0].analysis[0].readingSummary[0].nDel[0];
      var insert = result.message.AAResult[0].analysis[0].readingSummary[0].nIns[0];
      var totalWords = result.message.AAResult[0].analysis[0].readingSummary[0].nRef[0];
    }
    const sum = parseInt(sub) + parseInt(del) + parseInt(insert);
    const percentage = Math.floor((sum / parseInt(totalWords)) * 100);
    console.log(percentage,'acc');
    return isNaN(percentage) ? 0 : percentage;
  };

  const calculateTotalScore = (accuracy, fluency) => {
    console.log(accuracy,fluency,'get avg');
    var value;
if (parseInt(accuracy) <= 10) {
  value = 1;
} else if (parseInt(accuracy) < 21 && parseInt(accuracy) >= 11) {
  value = 1;
} else if (parseInt(accuracy) < 31 && parseInt(accuracy) >= 21) {
  value = 2;
}else if (parseInt(accuracy) < 41 && parseInt(accuracy) >= 31) {
  value = 2;
} else if (parseInt(accuracy) < 51 && parseInt(accuracy) >= 41) {
  value = 3;
} else if (parseInt(accuracy) < 61 && parseInt(accuracy) >= 51) {
  value = 3;
}else if (parseInt(accuracy) < 71 && parseInt(accuracy) >= 61) {
  value = 3;
}else if (parseInt(accuracy) < 81 && parseInt(accuracy) >= 71) {
  value = 4;
} else if (parseInt(accuracy) < 91 && parseInt(accuracy) >= 81) {
  value = 5;
}else if (parseInt(accuracy) <= 100 && parseInt(accuracy) >= 91) {
  value = 5;
}

console.log(parseInt((value + fluency) / 2),'get value');
        return parseInt((value + fluency) / 2);
      };
    const calculatePercentage = (valueToCalculate, totalErrors) => {

        const percentage = Math.floor((valueToCalculate / totalErrors) * 100);
        return isNaN(percentage) ? 0 : percentage;
      };

  const getStampFromScore = (score) => {
console.log(score,'get score');
    let stampText = '/images/tryagain1.png';
  
    if (score >= 0 && score <= 0.3) {
      stampText = '/images/tryagain1.png';
    } else if (score > 0.3 && score <= 0.6) {
      stampText = '/images/tryagain1.png';
    } else if (score > 0.6 && score <= 0.9) {
      stampText = '/images/tryagain1.png';
    } else if (score > 0.9 && score <= 1.3) {
      stampText = '/images/tryagain1.png';
    } else if (score > 1.3 && score <= 1.6) {
      stampText = '/images/tryagain1.png';
    } else if (score > 1.6 && score <= 1.9) {
      stampText = '/images/tryagain1.png';
    } else if (score > 1.9 && score <= 2.3) {
      stampText = '/images/tryagain1.png';
    } else if (score > 2.3 && score <= 2.6) {
      stampText = '/images/tryagain1.png';
    } else if (score > 2.6 && score <= 3) {
      stampText = '/images/greatjob1.png';
    } else if (score > 3 && score <= 3.3) {
      stampText = '/images/amazing1.png';
    } else if (score > 3.3 && score <= 4) {
      stampText = '/images/excellent1.png';
    }
  
    return stampText;
  };
  const getStampFromVocabScore = (score) => {
    let stampText = '/images/tryagain1.png';
  var score = parseInt(score);
    if (score >= 10 && score <= 20) {
      stampText = '/images/tryagain1.png';
    } else if (score > 20 && score <= 30) {
      stampText = '/images/tryagain1.png';
    } else if (score > 30 && score <= 40) {
      stampText = '/images/tryagain1.png';
    } else if (score > 40 && score <= 50) {
      stampText = '/images/greatjob1.png';
    } else if (score > 50 && score <= 60) {
      stampText = '/images/greatjob1.png';
    } else if (score > 60 && score <= 70) {
      stampText = '/images/greatjob1.png';
    } else if (score > 70 && score <= 80) {
      stampText = '/images/amazing1.png';
    } else if (score > 80 && score <= 90) {
      stampText = '/images/amazing1.png';
    } else if (score > 90 && score <= 100) {
      stampText = '/images/excellent1.png';
    }
  
    return stampText;
  };

  const renderStarsForVocab = (rating) => {
    console.log(rating,'get rating');
    var value;
    if (parseInt(rating) <= 10) {
      value = 1;
    } else if (parseInt(rating) < 21 && parseInt(rating) >= 11) {
      value = 1;
    } else if (parseInt(rating) < 31 && parseInt(rating) >= 21) {
      value = 2;
    }else if (parseInt(rating) < 41 && parseInt(rating) >= 31) {
      value = 2;
    } else if (parseInt(rating) < 51 && parseInt(rating) >= 41) {
      value = 3;
    } else if (parseInt(rating) < 61 && parseInt(rating) >= 51) {
      value = 3;
    }else if (parseInt(rating) < 71 && parseInt(rating) >= 61) {
      value = 3;
    }else if (parseInt(rating) < 81 && parseInt(rating) >= 71) {
      value = 4;
    } else if (parseInt(rating) < 91 && parseInt(rating) >= 81) {
      value = 4;
    }else if (parseInt(rating) <= 100 && parseInt(rating) >= 91) {
      value = 5;
    }

    let stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <i
          key={i}
          className={i <= value ? 'fa fa-star active' : 'fa fa-star'}
          aria-hidden="true"
        />
      );
    }
    return stars;
  };

  const renderStarsForReadOut = (rating) => {
 console.log(rating,'get read');
 if(rating == 4){
  rating = 5;
 }
    let stars = [];
    for (let i = 1; i <= 5; i++) {
      stars.push(
        <i
          key={i}
          className={i <= rating ? 'fa fa-star active' : 'fa fa-star'}
          aria-hidden="true"
        />
      );
    }
    return stars;
  };
  
  console.log(homework,'data');
    return (
        <div class="homework-vocabulary-type container">
        <div class="title-container">
            <h2>Homework</h2>
            <h2>Select Your Mission !</h2>
            <p>{(homework && homework.data) ? homework.data.Title : ''}</p>
        </div>
        {
          (homework && homework.data && homework.data.Programs) ? 
   <>
        <div class="row">
          {(homework && homework.data && homework.data.Programs['Textbook Vocab Quiz'])  ?   
             <div class="col-md-4 read">
            {(vocabResult) ?  <a href={`/Homework/VocabularyQuiz/${homework.data.Audio_Records['Textbook Vocab'].id}`}>
                <div class="book-card">
                    <h3>Textbook Vocab Quiz</h3>
                    <img class="cover-img" src="/images/text_book_vac_one.png" />
                
                    <img src={getStampFromVocabScore(homework.data.Audio_Records['Textbook Vocab'].total_score)} alt="Uploaded File" class="img-fluid recording-list" />
                    <div class="rating">
                    {/* <i class="fa fa-star active" aria-hidden="true"></i>
                    <i class="fa fa-star active" aria-hidden="true"></i>
                    <i class="fa fa-star active" aria-hidden="true"></i>
                    <i class="fa fa-star active" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i> */}
                    {renderStarsForVocab(homework.data.Audio_Records['Textbook Vocab'].total_score)}

                </div>
   
                </div>
                </a> :  
                 <a href={`/vocabularyQuiz/${homework.data.Programs['Textbook Vocab Quiz'].id}`}>
                <div class="book-card">
                    <h3>Textbook Vocab Quiz</h3>
                    <img class="cover-img" src="/images/text_book_vac_one.png" />
                    {/* <div class="rating">
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                    </div> */}
                </div>
                </a>}
            </div> : ''}
            {(homework && homework.data && homework.data.Programs['Listening Vocabulary Dictation']) ?
            <div class="col-md-4 read">
            {(listenVocabResult) ? <a href={`/Homework/VocabularyQuiz/${homework.data.Audio_Records['Listen Vocab'].id}`}> 
                <div class="book-card">
                    <h3>Listening: Vocabulary Dictation</h3>
                    <img class="cover-img" src="/images/listwrd.jpg" />
                   <img src={getStampFromVocabScore(homework.data.Audio_Records['Listen Vocab'].total_score)} alt="Uploaded File" class="img-fluid recording-list" />
                    <div class="rating">
                    {renderStarsForVocab(homework.data.Audio_Records['Listen Vocab'].total_score)}
                    </div>
                </div>
                </a>: <a href={`/listeningvocabularyQuiz/${homework.data.Programs['Listening Vocabulary Dictation'].id}`}>
                  <div class="book-card">
                    <h3>Listening: Vocabulary Dictation</h3>
                    <img class="cover-img" src="/images/listwrd.jpg" />
                   
                </div></a>}
            </div>
            :''}
            {(homework && homework.data && homework.data.Programs['Textbook Read Out Loud']) ?
            <div class="col-md-4 read">
                {result ?  <a href={`/ReadOutLoudGrader/${homework.data.Audio_Records['Read Out Loud'].id}`}>
                    <div class="book-card">
                    <h3>Textbook Read out Loud</h3>
                    <img class="cover-img" src="/images/textbook_Read_out_Loud.jpg" />
                   <img src={getStampFromScore(calculateTotalScore( calculateAccuracyPercentage(result),
        getFluencyAverageScore(result.message.AAResult[0].analysis[0].speakingSummary[0].wpm[0]) ))} alt="Uploaded File" class="img-fluid recording-list" />
                   
                    <div class="rating">
                    {renderStarsForReadOut(calculateTotalScore( calculateAccuracyPercentage(result),
        getFluencyAverageScore(result.message.AAResult[0].analysis[0].speakingSummary[0].wpm[0]) ))} 
                    
                    </div>
                </div>
                </a> :  
                <a href={`/HomeWork/read-out-loud-by-program/${homework.data.Programs['Textbook Read Out Loud'].id}`}>
                    <div class="book-card">
                    <h3>Textbook Read out Loud</h3>
                    <img class="cover-img" src="/images/textbook_Read_out_Loud.jpg" />
                    {(result) ?  <img src={getStampFromScore(showTotalScore(calculatePercentage(result.message.AAResult[0].analysis[0].readingSummary[0].nErr[0],
            result.message.AAResult[0].analysis[0].readingSummary[0].nRef[0])))} alt="Uploaded File" class="img-fluid recording-list" /> : ''}
                   
                    {/* <div class="rating">
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                    </div> */}
                </div>
                </a>}
               
            </div>
            :''}
        
        </div>
    
        <div class="row">
        {(homework && homework.data && homework.data.Programs['Vocabulary Read out Loud']) ?
            <div class="col-md-4 read">
            {(vocabReadResult) ?  <a href={`/ReadOutLoudGrader/${homework.data.Audio_Records['Vocab Read Out Loud'].id}`}>
                <div class="book-card">
                    <h3>Vocabulary Read Out Loud </h3>
                    <img class="cover-img" src="/images/read_out_loud_one.png" />
                    <img src={getStampFromScore(calculateTotalScore( calculateAccuracyPercentage(vocabReadResult),
        getFluencyAverageScore(vocabReadResult.message.AAResult[0].analysis[0].speakingSummary[0].wpm[0]) ))} alt="Uploaded File" class="img-fluid recording-list" />
                    <div class="rating">
                    {renderStarsForReadOut(calculateTotalScore( calculateAccuracyPercentage(vocabReadResult),
        getFluencyAverageScore(vocabReadResult.message.AAResult[0].analysis[0].speakingSummary[0].wpm[0]) ))} 
                    </div>
                </div>
                </a>
                : <a href={`/textbookvocabReadoutloudByProgram/${homework.data.Programs['Vocabulary Read out Loud'].id}`}>
                 <div class="book-card">
                    <h3>Vocabulary Read Out Loud </h3>
                    <img class="cover-img" src="/images/read_out_loud_one.png" />
                  
                    {/* <div class="rating">
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                    </div> */}
                </div></a>}
            </div>
            :''}
                {(homework && homework.data && homework.data.Programs['Sentence Read Out Loud']) ?
            <div class="col-md-4 read">
            {(sentenceReadResult) ?  <a href={`/ReadOutLoudGrader/${homework.data.Audio_Records['Sentence Read Out Loud'].id}`}>
                <div class="book-card">
                    <h3>Sentence Read-Out-Loud</h3>
                    <img class="cover-img" src="/images/sentence_read_load.png" />
                    <img src={getStampFromScore(calculateTotalScore( calculateAccuracyPercentage(sentenceReadResult),
        getFluencyAverageScore(sentenceReadResult.message.AAResult[0].analysis[0].speakingSummary[0].wpm[0]) ))} alt="Uploaded File" class="img-fluid recording-list" />
                    <div class="rating">
                    {renderStarsForReadOut(calculateTotalScore( calculateAccuracyPercentage(sentenceReadResult),
        getFluencyAverageScore(sentenceReadResult.message.AAResult[0].analysis[0].speakingSummary[0].wpm[0]) ))} 
                    </div>
                    {/* <div class="rating">
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                    </div> */}
                </div></a>
                : <a href={`/sentenceReadoutloud/${homework.data.Programs['Sentence Read Out Loud'].id}`}>
                  <div class="book-card">
                    <h3>Sentence Read-Out-Loud</h3>
                    <img class="cover-img" src="/images/sentence_read_load.png" />
                    {/* <div class="rating">
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                    </div> */}
                </div></a>
                }
            </div>
            :''}
            {(homework && homework.data && homework.data.Programs['Speaking Respond In English']) ?
            <div class="col-md-4 read">
            {(respondReadResult) ? 
             <a href={`/ReadOutLoudGrader/${homework.data.Audio_Records['Speaking Respond In English'].id}`}>
                <div class="book-card">
                    <h3>Speaking: Respond in English</h3>
                    <img class="cover-img" src="/images/speacking_response_english.jpg" />
                    <img src={getStampFromScore(calculateTotalScore( calculateAccuracyPercentage(respondReadResult),
        getFluencyAverageScore(respondReadResult.message.AAResult[0].analysis[0].speakingSummary[0].wpm[0]) ))} alt="Uploaded File" class="img-fluid recording-list" />
                    <div class="rating">
                    {renderStarsForReadOut(calculateTotalScore( calculateAccuracyPercentage(respondReadResult),
        getFluencyAverageScore(respondReadResult.message.AAResult[0].analysis[0].speakingSummary[0].wpm[0]) ))} 
                    </div>
                    {/* <div class="rating">
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                    </div> */}
                </div>
                </a>: <a href={`/speaking-respond-in-english/${homework.data.Programs['Speaking Respond In English'].id}`}>
                  <div class="book-card">
                    <h3>Speaking: Respond in English</h3>
                    <img class="cover-img" src="/images/speacking_response_english.jpg" />
                    {/* <div class="rating">
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                    </div> */}
                </div></a>}
            </div>	
            :''}
        </div>
        
        <div class="row">
        {(homework && homework.data && homework.data.Programs['Sentence Unscramble']) ?
            <div class="col-md-4 read">
            {(unscrambleResult) ?  <a href={`/Homework/VocabularyQuiz/${homework.data.Audio_Records['Sentence Unscramble'].id}`}>
                <div class="book-card">
                    <h3>Sentence Unscramble</h3>
                    <img class="cover-img" src="/images/sentencescrambleimg.jpg" />
                    {/* <div class="rating">
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                    </div> */}
                </div>
                </a>: <a href={`/sentence-unscramble/${homework.data.Programs['Sentence Unscramble'].id}`}>
                <div class="book-card">
                    <h3>Sentence Unscramble</h3>
                    <img class="cover-img" src="/images/sentencescrambleimg.jpg" />
                    {/* <div class="rating">
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                    </div> */}
                </div>
                </a>}
            </div>
            :''}
            {(homework && homework.data && homework.data.Programs['Listening: Sentence Dictation']) ?
            <div class="col-md-4 read">
            {(listenDictationResult) ?  <a href={`/Homework/VocabularyQuiz/${homework.data.Audio_Records['Listening Sentence Dictation'].id}`}>
                <div class="book-card">
                    <h3>Listening: Sentence Dictation</h3>
                    <img class="cover-img" src="/images/listnsentc.jpg" />
                    {/* <div class="rating">
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                    </div> */}
                </div></a>: <a href={`/listening-sentence-dictation/${homework.data.Programs['Listening: Sentence Dictation'].id}`}>
                <div class="book-card">
                    <h3>Listening: Sentence Dictation</h3>
                    <img class="cover-img" src="/images/listnsentc.jpg" />
                    {/* <div class="rating">
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                    </div> */}
                </div></a>}
            </div>
            :''}
             {(homework && homework.data && homework.data.Programs['Listening Fill in the Blanks']) ?
           
            <div class="col-md-4 read">
              {(listenFillInBlankResult) ? 
              <a href={`/Homework/VocabularyQuiz/${homework.data.Audio_Records['Listening Fill in the Blanks'].id}`}>
             <div class="book-card">
                    <h3>Listening: Fill in the Blanks</h3>
                    <img class="cover-img" src="/images/listnsentc.jpg" />
                    <img src={getStampFromVocabScore(homework.data.Audio_Records['Listening Fill in the Blanks'].total_score)} alt="Uploaded File" class="img-fluid recording-list" />
                    <div class="rating">
                    {/* <i class="fa fa-star active" aria-hidden="true"></i>
                    <i class="fa fa-star active" aria-hidden="true"></i>
                    <i class="fa fa-star active" aria-hidden="true"></i>
                    <i class="fa fa-star active" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i> */}
                    {renderStarsForVocab(homework.data.Audio_Records['Listening Fill in the Blanks'].total_score)}
                    </div>
                </div>
                </a> :  
                <a href={`/listening-fill-in-the-blanks/${homework.data.Programs['Listening Fill in the Blanks'].id}`}>
              <div class="book-card">
                     <h3>Listening: Fill in the Blanks</h3>
                     <img class="cover-img" src="/images/listnsentc.jpg" />
 
                 </div>
                 </a>}
              
        
        </div>  :''}

        {(homework && homework.data && homework.data.Programs['Grammer MCQ']) ?
           
           <div class="col-md-4 read">
             {(grammarMcqResult) ? 
             <a href={`/Homework/VocabularyQuiz/${homework.data.Audio_Records['Grammar MCQ'].id}`}>
            <div class="book-card">
                   <h3>Grammar Quiz</h3>
                   <img class="cover-img" src="/images/listnsentc.jpg" />
                   <img src={getStampFromVocabScore(homework.data.Audio_Records['Grammar MCQ'].total_score)} alt="Uploaded File" class="img-fluid recording-list" />
                    <div class="rating">
                    {/* <i class="fa fa-star active" aria-hidden="true"></i>
                    <i class="fa fa-star active" aria-hidden="true"></i>
                    <i class="fa fa-star active" aria-hidden="true"></i>
                    <i class="fa fa-star active" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i> */}
                    {renderStarsForVocab(homework.data.Audio_Records['Grammar MCQ'].total_score)}
                    </div>
               </div>
               </a> :  
               <a href={`/grammar-quiz${homework.data.Programs['Grammer MCQ'].id}`}>
             <div class="book-card">
                    <h3>Grammar Quiz</h3>
                    <img class="cover-img" src="/images/listnsentc.jpg" />

                </div>
                </a>}
             
       
       </div>  :''}
        </div>

        <div class="row">
        {(homework && homework.data && homework.data.Programs['Reading Comprehension']) ?
            <div class="col-md-4 read">
            {(readingComprehensionResult) ?  <a href={`/Homework/VocabularyQuiz/${homework.data.Audio_Records['Reading Comprehension'].id}`}>
                <div class="book-card">
                    <h3>Reading Comprehension</h3>
                    <img class="cover-img" src="/images/sentencescrambleimg.jpg" />
                    <img src={getStampFromVocabScore(homework.data.Audio_Records['Reading Comprehension'].total_score)} alt="Uploaded File" class="img-fluid recording-list" />
                    <div class="rating">
                    {/* <i class="fa fa-star active" aria-hidden="true"></i>
                    <i class="fa fa-star active" aria-hidden="true"></i>
                    <i class="fa fa-star active" aria-hidden="true"></i>
                    <i class="fa fa-star active" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i> */}
                    {renderStarsForVocab(homework.data.Audio_Records['Reading Comprehension'].total_score)}
                    </div>
         
                </div>
                </a>: <a href={`/reading-comprehension-question/${homework.data.Programs['Reading Comprehension'].id}`}>
                <div class="book-card">
                    <h3>Reading Comprehension</h3>
                    <img class="cover-img" src="/images/sentencescrambleimg.jpg" />
                    {/* <div class="rating">
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                    </div> */}
                </div>
                </a>}
            </div>
            :''}

{(homework && homework.data && homework.data.Programs['Listening Comprehension']) ?
            <div class="col-md-4 read">
            {(listeningComprehensionResult) ?  <a href={`/Homework/VocabularyQuiz/${homework.data.Audio_Records['Listening Comprehension'].id}`}>
                <div class="book-card">
                    <h3>Listening Comprehension</h3>
                    <img class="cover-img" src="/images/sentencescrambleimg.jpg" />

              <img src={getStampFromVocabScore(homework.data.Audio_Records['Listening Comprehension'].total_score)} alt="Uploaded File" class="img-fluid recording-list" />
                    <div class="rating">
                    {/* <i class="fa fa-star active" aria-hidden="true"></i>
                    <i class="fa fa-star active" aria-hidden="true"></i>
                    <i class="fa fa-star active" aria-hidden="true"></i>
                    <i class="fa fa-star active" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i> */}
                    {renderStarsForVocab(homework.data.Audio_Records['Listening Comprehension'].total_score)}
                    </div>
                </div>
                </a>: <a href={`/listening-comprehension-question/${homework.data.Programs['Listening Comprehension'].id}`}>
                <div class="book-card">
                    <h3>Listening Comprehension</h3>
                    <img class="cover-img" src="/images/sentencescrambleimg.jpg" />
                    {/* <div class="rating">
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star active" aria-hidden="true"></i>
                        <i class="fa fa-star" aria-hidden="true"></i>
                    </div> */}
                </div>
                </a>}
            </div>
            :''}


        </div>
        </>
        : <p>No Programs Yet.</p>}
        <div class="score-type-main">
            <div class="score-type-sub"><span class="spn-score-type-sub1">0-19</span><span class="spn-score-type-sub2">(Fail/Poor/Bad)</span></div>
            <div class="score-type-sub"><span class="spn-score-type-sub1">20-39</span><span class="spn-score-type-sub2">(Try Harder/Okay/Good)</span></div>
            <div class="score-type-sub"><span class="spn-score-type-sub1">40-59</span><span class="spn-score-type-sub2">(Very Good/Great/Awesome)</span></div>
            <div class="score-type-sub"><span class="spn-score-type-sub1">60-79</span><span class="spn-score-type-sub2">(Excellent/Super/Brilliant)</span></div>
            <div class="score-type-sub"><span class="spn-score-type-sub1">80-100</span><span class="spn-score-type-sub2">(Outstanding/Exceptional/Amazing)</span></div>
        </div>
    </div>

      
    )
}
