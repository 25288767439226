import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { studentrecordingsActions, homeworkActions ,studentActions} from "_store";
import { Modal, Button } from 'react-bootstrap';
import { history } from '_helpers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export { MyRecordingList };

function MyRecordingList() {
  const dispatch = useDispatch();
  const { user: authUser } = useSelector((x) => x.auth);
  const { studentRecordings } = useSelector((x) => x.studentRecordings);
  const [file, setFile] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { student } = useSelector((x) => x.student);
  const { homework } = useSelector((x) => x.homework);

console.log(student,'get ',homework);
  const handleDownload = (fileUrl) => {
    const fileName = 'recording.mp3'; // Replace with your desired file name

    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  };

  useEffect(() => {
    if (!authUser){
      toast.error('Please login to view this page.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
      history.navigate('/login');
    }else{
      var student_id = authUser.user.student_id;
      var userId = authUser.user.student_id;
       dispatch(studentrecordingsActions.studentRecordings({ student_id }));
      dispatch(studentActions.getStudentById({ userId }));
      var type = 'Speaking';
    }

    //var type = student;
   // dispatch(homeworkActions.getHomeWorkById({class_id,type}));

  }, []);

  useEffect(() => {
    if(student){
      var type = 'Speaking';
      var class_id = student.Class.id;
     dispatch(homeworkActions.getHomeWorkById({class_id,type}));
    }
  

 }, [student]);

  const [showDialog, setShowDialog] = useState(false);

  const handleDelete = (record_id) => {
    // Show the dialog when delete action is clicked
    setShowDialog(true);
  };

  const handleCancel = () => {
    // Hide the dialog when cancel action is clicked
    setShowDialog(false);
  };

  const handleConfirmDelete = async(record_id) => {
    // Handle the deletion here
    console.log('Deleting...', record_id);
   
     // Hide the dialog after deletion
    setShowDialog(false);
     // Call your deletePlan function here
  return  dispatch(studentrecordingsActions.deleteStudentRecording({ record_id }));
 
  };

  const getStampFromScore = (score) => {
    let stampText = '/images/tryagain1.png';
  
    if (score >= 0 && score <= 0.3) {
      stampText = '/images/tryagain1.png';
    } else if (score > 0.3 && score <= 0.6) {
      stampText = '/images/tryagain1.png';
    } else if (score > 0.6 && score <= 0.9) {
      stampText = '/images/tryagain1.png';
    } else if (score > 0.9 && score <= 1.3) {
      stampText = '/images/goodjob1.png';
    } else if (score > 1.3 && score <= 1.6) {
      stampText = '/images/goodjob1.png';
    } else if (score > 1.6 && score <= 1.9) {
      stampText = '/images/goodjob1.png';
    } else if (score > 1.9 && score <= 2.3) {
      stampText = '/images/greatjob1.png';
    } else if (score > 2.3 && score <= 2.6) {
      stampText = '/images/greatjob1.png';
    } else if (score > 2.6 && score <= 2.9) {
      stampText = '/images/greatjob1.png';
    } else if (score > 2.9 && score <= 3.3) {
      stampText = '/images/amazing1.png';
    } else if (score > 3.3 && score <= 3.6) {
      stampText = '/images/excellent1.png';
    }
  
    return stampText;
  };


  return (
    <section className="custom-sign">
    <div className="container">
	<div className="record-list">
		<h2 className="list-head">My Recording List</h2>
      <div className="row">
	  
        <div className="col-md-12">
		<label>Search</label>
        <input type="text" placeholder="Search" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
          </div>
          <div className="col-md-2">
          <a href="/recorder-list"><input type="button" value="Upload File" /></a>
          </div>
          <div className="col-md-10">
          {(studentRecordings) ? studentRecordings.data.map((recording, index) => ( 

            <div className="position-relative">
            <a href={`/myrecording/speaking-grade/${recording.id}`}>
            <div className='user-name-div'>{authUser.user.name}</div>
            <img
                src={getStampFromScore(recording.total_score)}
                alt="Uploaded File"
                className="img-fluid recording-list"
              />
            </a>
              <div className="overlay">
              <button
                  className="btn btn-sm btn-success"
                  onClick={()=>handleDownload(recording.audio)}
                >
                  <i className="fa fa-download"></i>
                </button>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() => handleDelete(recording.id)}
                >
                  <i className="fa fa-trash"></i>
                </button>
                <Modal show={showDialog} onHide={handleCancel}>
                <Modal.Header closeButton>
                  <Modal.Title>Delete Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>Are you sure you want to delete this?</Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button variant="danger" onClick={() => handleConfirmDelete(recording.id)}>
                    Delete
                  </Button>
                </Modal.Footer>
              </Modal>
                
              </div>
            </div>
          )): ''}

          {(homework) ? homework.data.map((work, index) => ( 
<>
          {(work.homeworkArray) ? work.homeworkArray.map((home, index) => (
            <>
            {(home.homework) ? home.homework.map((homeW, index1) => (
          <div className="position-relative">
          <a href={(homeW.category == 'Read Out Loud') ? `/HomeWork/read-out-loud/${homeW.id}` : `/HomeWork/free-response/${homeW.id}`}>
          <div className='user-name-div'>Homework-{homeW.type}</div>
          <div className='cteated-date'> {homeW.createdAt}</div>
          {/* <img
              src={getStampFromScore(recording.total_score)}
              alt="Uploaded File"
              className="img-fluid recording-list"
            /> */}
          </a>
            <div className="overlay">
            {/* <button
                className="btn btn-sm btn-success"
    >
      <i className="fa fa-download"></i>
    </button>
    <button
      className="btn btn-sm btn-danger"
    >
      <i className="fa fa-trash"></i>
    </button> */}
    <Modal show={showDialog} onHide={handleCancel}>
    <Modal.Header closeButton>
      <Modal.Title>Delete Confirmation</Modal.Title>
    </Modal.Header>
    <Modal.Body>Are you sure you want to delete this?</Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleCancel}>
        Cancel
      </Button>
      <Button variant="danger" onClick={() => handleConfirmDelete(homeW.id)}>
        Delete
      </Button>
    </Modal.Footer>
  </Modal>
    
  </div>
          </div>
            )) : ''}
            </>
            )) : ''}
            </>
)): ''}

     
        </div>
      </div>
    </div>
  </div>

</section>
    )
}
